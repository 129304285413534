import React, { useMemo, ReactNode } from "react";
import styled from "styled-components";
import { OrganizationModelAccessType, ModelStatus } from "models/Model";
import { IAppContext } from "views/interfaces";
import { withRouter } from "react-router";
import breakpoint from "styled-components-breakpoint";
import { MODEL_ROUTES } from "views/routes";
import { useTranslation } from "react-i18next";
import Box from "views/shared/components/Box";
import { Link } from "react-router-dom";
import Button from "views/shared/buttons/Button";
import DateComponent from "views/shared/components/Date";
import {
  MODEL_DETAILS_PAGE_TAB_PATH_KEY,
  ModelDetailsPageTabKeys,
} from "const/global";
import { trimStringIfToLong } from "utils/stringUtils";
import DateLabel from "views/shared/typography/DateLabel";
import ModelListElementOptions, {
  IModelListElementOptionsPropTypes,
} from "./components/ModelListElementOptions";
import ModelListElementVideoSection from "./components/ModelListElementVideoSection";
import useBreakpoint from "views/shared/hook/useBreakpointHook";
import ModelListElementTags from "./components/ModelListElementTags";
import LightButton from "../../../../../shared/buttons/LightButton";
import UploadIcon from "../../../../../shared/Icons/UploadIcon";
import AlertIcon from "../../../../../shared/Icons/AlertIcon";
import classNames from "classnames";
import ModelListElementUnlockUI, {
  IModelListElementUnlockUIPropTypes,
  UnlockUIType,
} from "./components/ModelListElementUnlockUI";
import { useModelExpirationTimeLeft } from "../../../../hooks/useModelExpirationTime";
import { IModelListItem } from "../interface";

interface IModelRowPropTypes extends IModelListElementOptionsPropTypes {
  accessType: OrganizationModelAccessType;
  accessLocked?: boolean;
  badge?: ReactNode;
  showUnlockUI?: boolean;
  showExpiresAt?: boolean;
  unlockUIProps?: IModelListElementUnlockUIPropTypes;
  shareContent?: any;
  showOptions: boolean;
  allowToShowUploadButton?: boolean;
  model: IModelListItem;
  mapRedirectUrl: string;
  modelRedirectUrl: string;
}

export const UNLOCK_UI_MAP: Record<string, UnlockUIType> = {
  [OrganizationModelAccessType.Archived]: "recover",
  [OrganizationModelAccessType.Expired]: "unlock",
  [OrganizationModelAccessType.Preview]: "buy",
};

export const unlockUIType = (accessType: OrganizationModelAccessType) => {
  return UNLOCK_UI_MAP[accessType] || null;
};

const ModelRowContainer = styled(Link)`
  width: 100%;
  display: flex;
  margin-bottom: 26px;
  border: ${(p: IAppContext) => p.theme.skye.borders.primary};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  align-items: flex-start;
  overflow: hidden;
  justify-content: flex-start;
  flex-direction: column;

  ${breakpoint("md")`
    min-height: 230px;
    flex-direction: row;
  `}

  &.accessLocked {
    cursor: not-allowed;
  }

  &:first-child {
    margin-top: 30px;
  }

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0px 0px 46px rgba(0, 0, 0, 0.1);
  }

  .model-video-container {
    max-height: 150px;
    min-height: 150px;
  }
`;
const ContentWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  padding: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  min-height: 230px;

  ${breakpoint("md")`
    padding: 32px 20px 32px 32px;
  `}
  .description {
    margin-bottom: 0;
  }
`;
const TitleRow = styled(Box)`
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;
const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  margin: 0 0 12px 0;
  padding: 0;
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};

  ${breakpoint("md")`
    margin: 0;
  `}
`;
const ButtonsRow = styled(Box)`
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.small};

  .btn {
    width: 100%;
  }

  ${breakpoint("md")`
    flex-direction: row;

    .btn {
      width: auto;
    }
  `}
`;
const MobileButtonsRow = styled.div`
  .btn::first-of-type {
    margin-right: 10px;
  }
`;
const TagsAndOptionsRow = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
`;
const ButtonRowLeftContent = styled(Box)``;
const RightTopContentWrapper = styled(Box)`
  align-items: center;
  justify-content: center;
`;
const DateWrapper = styled(DateLabel)`
  text-align: left;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};

  ${breakpoint("md")`
    text-align: right;
  `}
`;
const TopContentRow = styled(Box)`
  flex-direction: column;
`;

const ShareContentWrapper = styled(Box)`
  min-width: 300px;
`;

const ModelListElement = (props: IModelRowPropTypes) => {
  const {
    accessLocked,
    badge,
    shareContent = "",
    showOptions,
    modelRedirectUrl,
    mapRedirectUrl,
    allowToShowUploadButton,
    showUnlockUI,
    showExpiresAt,
    unlockUIProps,
  } = props;

  if (showUnlockUI && !unlockUIProps)
    throw new Error("unlockUIProps is required when showUnlockUI is true");

  const { model, history } = props;

  const { t } = useTranslation();
  const expireInfo = useModelExpirationTimeLeft({
    modelStatus: model.status,
    expirationInfo: model.expirationInfo,
    accessType: model.accessType,
  });

  const { breakpoint } = useBreakpoint();
  const isDesktop = breakpoint("md");
  const showUploadButton =
    allowToShowUploadButton && model?.status === ModelStatus.Initialized;

  const openModel = (e: any) => {
    e.preventDefault();
    if (accessLocked) return;

    history.push(modelRedirectUrl);
  };

  const openUpload = (e: any) => {
    e.preventDefault();
    if (accessLocked) return;

    const baseUrl = MODEL_ROUTES.details.replace(":id", model.id);
    const tabPathKey = `?${MODEL_DETAILS_PAGE_TAB_PATH_KEY}=${ModelDetailsPageTabKeys.EmergencyUpload}`;
    history.push(`${baseUrl}${tabPathKey}`);
  };

  const handleClick = (e: any) => {
    if (accessLocked) e.preventDefault();
  };

  const dateElement = (
    <DateWrapper>
      <DateComponent>{model?.created}</DateComponent>
    </DateWrapper>
  );

  const expiresInfo = useMemo(
    () =>
      showExpiresAt && (
        <div className="access-expiration-info w-full text-sm text-red-500 px-4">
          {expireInfo.expirationLabel}
        </div>
      ),
    [showExpiresAt, expireInfo.expirationLabel]
  );

  const desktopButtons = (
    <ButtonsRow>
      {model?.status === ModelStatus.InQueue && (
        <ButtonRowLeftContent
          className={classNames([{ "opacity-50": accessLocked }])}
        >
          <Button
            disabled
            loading
            loaderProps={{
              color: "primary",
            }}
          >
            {t("inQueue")}
          </Button>
        </ButtonRowLeftContent>
      )}
      {model?.status === ModelStatus.Processing && (
        <ButtonRowLeftContent
          className={classNames([{ "opacity-50": accessLocked }])}
        >
          <Button
            disabled
            loading
            loaderProps={{
              color: "primary",
            }}
          >
            {t("processing")}
          </Button>
        </ButtonRowLeftContent>
      )}
      {model?.status !== ModelStatus.Processing && (
        <ButtonRowLeftContent
          className={classNames([{ "opacity-50": accessLocked }])}
        >
          {showUploadButton && (
            <LightButton
              disabled={accessLocked}
              startIcon={<UploadIcon />}
              onClick={openUpload}
            >
              {t("uploadFiles")}
            </LightButton>
          )}
          {model?.status === ModelStatus.Failed && (
            <Button color="accent" startIcon={<AlertIcon />} disabled>
              {t("failed")}
            </Button>
          )}
          {model?.status === ModelStatus.Finished && (
            <Button
              disabled={model?.status !== ModelStatus.Finished}
              onClick={openModel}
            >
              {t("viewModel")}
            </Button>
          )}
        </ButtonRowLeftContent>
      )}
      <div className="flex justify-end items-center flex-row">
        <div className="text-right w-full">{expiresInfo}</div>
        {showUnlockUI && (
          <div className="access-data-wrapper">
            <ModelListElementUnlockUI {...unlockUIProps} />
          </div>
        )}
      </div>
      {isDesktop && !!shareContent && (
        <ShareContentWrapper className="share-content-wrapper">
          {shareContent}
        </ShareContentWrapper>
      )}
    </ButtonsRow>
  );

  const mobileButtons = (
    <MobileButtonsRow>
      {showUnlockUI && (
        <div className="access-data-wrapper flex items-center justify-start mb-4 text-left">
          <ModelListElementUnlockUI {...unlockUIProps} />
          <div className="w-full">{expiresInfo}</div>
        </div>
      )}
      {!showUnlockUI && (
        <>
          {showUploadButton && (
            <LightButton
              disabled={accessLocked}
              fullSize
              startIcon={<UploadIcon />}
              onClick={openUpload}
            >
              {t("uploadFiles")}
            </LightButton>
          )}
          {model.status === ModelStatus.Finished && (
            <Button fullSize onClick={openModel}>
              {t("modelView")}
            </Button>
          )}
          {model.status === ModelStatus.Processing && (
            <Button
              fullSize
              disabled
              loading
              loaderProps={{
                color: "primary",
              }}
            >
              {t("processing")}
            </Button>
          )}
          {model.status === ModelStatus.Failed && (
            <Button fullSize color="accent" startIcon={<AlertIcon />} disabled>
              {t("failed")}
            </Button>
          )}
        </>
      )}
    </MobileButtonsRow>
  );

  return (
    <div>
      <ModelRowContainer
        className={classNames([{ accessLocked }])}
        onClick={handleClick}
        to={mapRedirectUrl}
      >
        <div className="video-wrapper-outter w-full h-full md:w-fit relative bg-gray-100">
          {!!badge && (
            <div className="access-badge-wrapper absolute top-2 left-2 z-10">
              {badge}
            </div>
          )}
          <div
            className={classNames([
              "video-wrapper-inner",
              { "opacity-50": accessLocked },
            ])}
          >
            <ModelListElementVideoSection
              {...props}
              modelId={model.id}
              videoDuration={model.videoDuration}
              thumbnailsUrls={model.assets.thumbnailsUrls}
              detailsPageRoute={mapRedirectUrl}
            />
          </div>
        </div>
        <ContentWrapper>
          <TopContentRow
            className={classNames([{ "opacity-50": accessLocked }])}
          >
            <TagsAndOptionsRow className="top-content-row">
              <ModelListElementTags
                tags={model.tags}
                browseType={model.browseType}
                resolution={model.resolution}
              />
              <RightTopContentWrapper>
                {isDesktop && dateElement}
                {showOptions && (
                  <ModelListElementOptions model={model} {...props} />
                )}
              </RightTopContentWrapper>
            </TagsAndOptionsRow>
            {!isDesktop && dateElement}
            <TitleRow>
              <Title>{trimStringIfToLong(model.name, 40)}</Title>
              {!isDesktop && shareContent}
            </TitleRow>
          </TopContentRow>
          {!isDesktop && mobileButtons}
          {isDesktop && desktopButtons}
        </ContentWrapper>
      </ModelRowContainer>
    </div>
  );
};

export default withRouter(ModelListElement);
