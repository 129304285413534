import { MAP_URLS } from "const/global";
import { Weather } from "./Weather";
import * as H from "history";
import { IPotreeFeatures } from "../views/viewer/interfaces";
import { IOrganizationUser } from "./User";

export enum DeviceType {
  Drone = "drone",
  Camera = "other",
  Unknown = "",
}

export const AUTEL_CAMERA_TYPES = [
  "XT705",
  "XT709",
  "XT706",
  "Xteady H2",
  "XT701",
];

export enum DjiDroneGroups {
  DjiDronesMiniSeries = "DJI Mini Series",
  DjiDronesMavicSeries = "DJI Mavic Series",
  DjiDronesEnterpriseSeries = "DJI Enterprise Series",
  DJIDronesPhantomSeries = "DJI Phantom Series",
  DJIDronesInspireSeries = "DJI Inspire Series",
}

export enum AutelDroneGroups {
  AutelEvoSeries = "Autel Evo Series",
  AutelEnterpriseSeries = "Autel Enterprise Series",
}

export enum DjiDronesMiniSeries {
  DJIMini2 = "DJI Mini 2",
  DJIMiniSE = "DJI Mini SE",
}

export enum DjiDronesMavicSeries {
  MavicPro = "Mavic Pro",
  Mavic2Pro = "Mavic 2 Pro",
  MavicAir = "Mavic Air",
  MavicAir2 = "Mavic Air 2",
  DJIAir2S = "DJI Air 2S",
  Mavic2EnterpriseDual = "Mavic 2 Enterprise Dual",
  Mavic2EnterpriseAdvanced = "Mavic 2 Enterprise Advanced",
  DJIMavic3Enterprise = "DJI Mavic 3 Enterprise",
  DJIMavic3EnterpriseSeries = "DJI_MAVIC_3_ENTERPRISE_SERIES",
  M3E = "M3E",
}

export enum DjiDronesEnterpriseSeries {
  M30Series = "M30_SERIES",
  Matrice210 = "Matrice 210",
  Matrice210V2 = "Matrice 210 V2",
  Matrice300RTK = "Matrice 300 RTK",
  DJIMatrice30 = "DJI Matrice 30",
  DJIMatrice300 = "DJI Matrice 300",
}

export enum DJIDronesPhantomSeries {
  Phantom4 = "Phantom 4",
  Phantom4Pro = "Phantom 4 Pro",
}

export enum DJIDronesInspireSeries {
  Inspire2 = "Inspire 2",
}

export type DjiDroneModel =
  | DjiDronesMiniSeries
  | DjiDronesMavicSeries
  | DjiDronesEnterpriseSeries
  | DJIDronesPhantomSeries
  | DJIDronesInspireSeries;

export enum AutelEvoSeries {
  Evo = "Evo",
  EvoII = "Evo II",
}

export enum AutelEnterpriseSeries {
  Dragonfish = "Dragonfish",
}

export type AutelDroneModel = AutelEvoSeries | AutelEnterpriseSeries;

export const AUTEL_DRONE_TYPES: AutelDroneModel[] = [
  Object.values(AutelEvoSeries),
  Object.values(AutelEnterpriseSeries),
].flat();

export const DJI_DRONE_TYPES: DjiDroneModel[] = [
  Object.values(DjiDronesMiniSeries),
  Object.values(DjiDronesMavicSeries),
  Object.values(DjiDronesEnterpriseSeries),
  Object.values(DJIDronesPhantomSeries),
  Object.values(DJIDronesInspireSeries),
].flat();

export interface BaseTag {
  id: number;
  text: string;
}

export interface IModelStatuses {
  gpsUploaded: boolean;
  thumbnailCreated?: boolean;
}

export enum DroneManufacturer {
  Autel = "autel",
  Dji = "dji",
}

export enum BrowseType {
  Widebrowse = "widebrowse",
  Skyebrowse = "skyebrowse",
  External = "external",
  Manual = "manual",
  Interior = "interior",
  Unknown = "",
  Universal = "universal",
}

export interface WidebrowseSettings {
  sideScan?: boolean;
  wideBrowseAltitude?: number;
}

export interface SkyebrowseSettings {
  orbitCount: number;
  orbitRadius?: number;
  firstOrbitAltitude?: number;
  firstOrbitRadius?: number;
  secondOrbitAltitude?: number;
  secondOrbitRadius?: number;
}

export enum ModelResolution {
  notDefined = "",
  fullHD = "1080p",
  fourK = "4k",
}

export interface ModelDetails {
  archived: boolean;
  name: string;
  id: string;
  created: Date;
  ownerId: number;
  ownerOrg: number;
  droneType: string;
  cameraType: string;
  browseType: BrowseType;
  nightVision: boolean;
  settings: SkyebrowseSettings | WidebrowseSettings | null;
  shortDescription: string;
  address: string;
  partial: boolean;
  tags?: string[];
  accessType: ModelAccessType;
  resolution: ModelResolution;
  owner?: IOrganizationUser;
}

export type ITextureModelUrls = string[][];

export enum ITypedModelType {
  PointCloud18 = "pointcloud18",
  PointCloud = "pointcloud",
  TiledModel = "tile",
  TexturedModel = "texture",
  Unknown = "unknown",
  Orthophoto = "orthophoto",
  OrthophotoSketch = "orthophoto_sketch",
}

export interface IModelAssets {
  gpsUrl?: string;
  vidUrl?: string;
  lazFileUrl?: string;
  thumbnailsUrls?: string[];
  [ITypedModelType.PointCloud18]?: string;
  [ITypedModelType.TexturedModel]?: ITextureModelUrls;
  orthophotoUrl?: string;
  orthophotoSketchUrl?: string;
}

export interface ILocation {
  latitude: number;
  longitude: number;
}

export interface IModelLocation {
  id: number;
  point: ILocation;
}

export enum ModelStatus {
  Initialized = "Initialized",
  Processing = "Processing",
  Failed = "Failed",
  Finished = "Finished",
  Archiving = "Archiving",
  Archived = "Archived",
  InQueue = "InQueue",
  RestoringArchive = "RestoringArchive",
  Deleting = "Deleting",
  Deleted = "Deleted",
}

export interface ITypedModel {
  id: number;
  createdAt: Date;
  modifiedAt: Date;
  parent?: Model;
  type: ITypedModelType;
  status: ModelStatus;
  metadata: any;
}

export interface IModelMeasurement {
  measurements: IPotreeFeatures;
  userId: number;
}

export interface IModelInput {
  fileName: string;
  gpsLength: number;
  id: number;
  model: Model;
  vidUploaded: boolean;
  videoCreatedAt: Date;
  videoFileS3Name: string;
  videoSize: number;
}

export interface Model extends ModelDetails, IModelStatuses {
  accessExpiresAt: string;
  vidReject: boolean;
  vidRejectReason?: string;
  vidName: string;
  videoSize: number;
  videoDuration: number;
  publicKey: string;
  location: IModelLocation;
  weather: Weather | null;
  operatorAltitude?: number;
  droneManufacturer: DroneManufacturer;
  centerLocation?: IModelLocation;
  inputs?: IModelInput[];
  assets: IModelAssets;
  expirationInfo: IModelExpirationMap;
  status: ModelStatus;
  typedModels: ITypedModel[];
}

export enum ModelAccessType {
  Expired = "Expired",
  Premium = "Premium",
  Preview = "Preview",
  Archived = "Archived",
}

export interface IModelExpirationMap {
  [ModelAccessType.Premium]: Date;
  [ModelAccessType.Preview]: Date;
  [ModelAccessType.Expired]: Date;
  [ModelAccessType.Archived]: Date;
}

export enum OrganizationModelAccessType {
  Expired = "Expired",
  Premium = "Premium",
  Preview = "Preview",
  Archived = "Archived",
  Lite = "Lite",
}

export interface IPublicModel extends IModelStatuses, IModelAssets {
  id: string;
  name: string;
  address?: string;
  shortDescription?: string;
  created: Date;
  location: IModelLocation;
  weather: Weather | null;
  droneType: string;
  cameraType: string;
  browseType: BrowseType;
  nightVision: boolean;
  resolution: ModelResolution;
  settings: SkyebrowseSettings | WidebrowseSettings | null;
  publicKey: string;
  centerLocation?: IModelLocation;
  legacy?: boolean;
  type?: ITypedModelType;
  typedModels?: ITypedModel[];
  accessType: ModelAccessType;
  inputs?: IModelInput[];
  assets: IModelAssets;
  expirationInfo: IModelExpirationMap;
  status: ModelStatus;
  tags?: string[];
  accessExpiresAt: string | null;
}

interface ITextureModelChunk {
  objUrl: string;
  mtlUrl: string;
  mapUrl: string;
}

export type ModelLocationType = "location" | "centerLocation";

export class ModelHelpers {
  static hasPublicAccess(modelPublicKey: string) {
    return !!modelPublicKey;
  }

  static getLocation(
    model: IPublicModel,
    type: ModelLocationType = "location"
  ): ILocation | null {
    if (model[type]) {
      return {
        longitude: Number(model[type].point.longitude),
        latitude: Number(model[type].point.latitude),
      };
    }

    return {
      longitude: 0,
      latitude: 0,
    };
  }

  static getTexturedModel(model: IPublicModel): ITypedModel | null {
    if (ModelHelpers.isTypedModel(model) && model.typedModels) {
      return model.typedModels.find(
        (m) => m.type === ITypedModelType.TexturedModel
      );
    }
    return null;
  }

  static modelExist(model: IPublicModel) {
    return model.status && !!model.assets[ITypedModelType.PointCloud18];
  }

  static getPublicUrl(modelPublicKey: string) {
    return `${MAP_URLS.publicModelDetailsPage.replace(
      ":publicKey",
      modelPublicKey
    )}`;
  }

  static isTypedModel(model: IPublicModel) {
    return !model.legacy;
  }

  static isAutel(model: Model): boolean {
    if (
      model.droneManufacturer === DroneManufacturer.Autel ||
      AUTEL_CAMERA_TYPES.find((type) => model.cameraType === type) ||
      AUTEL_DRONE_TYPES.find((type) => model.droneType === type)
    ) {
      return true;
    }
    return false;
  }

  static videosUploaded(model: IPublicModel) {
    if (model.inputs?.length > 0) {
      return !!model.inputs.every((input) => input.vidUploaded);
    }
    return false;
  }

  static videoDurationToTimeLabel(videoDuration: number): string {
    if (videoDuration) {
      return new Date(Number(videoDuration) * 1000).toISOString().substr(14, 5);
    }
    return "00:00";
  }

  static isPublicOrSharedModelPage(location: H.Location) {
    return (
      location.pathname.includes("public") ||
      location.pathname.includes("shared")
    );
  }

  static getTextureModelChunks(model: IPublicModel): ITextureModelChunk[] {
    const urlSets = model.assets[ITypedModelType.TexturedModel] || [];
    const chunks = [];

    urlSets.forEach((urls) => {
      if (Array.isArray(urls)) {
        const objUrl = urls.find((url: string) => url.includes(`model.obj`));
        const mtlUrl = urls.find((url: string) => url.includes(`model.mtl`));
        const mapUrl = urls.find((url: string) => url.includes(`model.jpg`));

        if (objUrl && mtlUrl && mapUrl) {
          chunks.push({ objUrl, mtlUrl, mapUrl });
        }
      }
    });
    return chunks;
  }
}
