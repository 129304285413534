import { INavElement } from "./interfaces";

export const VIEWER_RENDER_AREA_ID = 'potree_render_area';

export const TEXT_LABEL_ID = "nav_annotation";
export const MEASUREMENTS_ID = "nav_measurements";
export const SCREENSHOTS_ID = "nav_screenshots";
export const REPORTS_ID = "nav_reports";

// Measurement tools
export const DISTANCE_TOOL = { id: "mt_distance", tooltip: "distance_tool" };
export const AREA_TOOL = { id: "mt_area", tooltip: "area_tool" };
export const ANGLE_TOOL = { id: "mt_angle", tooltip: "angle_tool" };
export const SLOPE_TOOL = {
  id: "mt_slope_tool",
  tooltip: "tooltipSlopeTool",
};
export const LOCATION_POINT_TOOL = {
  id: "mt_location_point",
  tooltip: "location_point_tool",
};
export const HEIGHT_TOOL = { id: "mt_height", tooltip: "height_tool" };
export const SWITCH_MEASUREMENT_UNIT_TOOL = {
  id: "mt_measurement_unit",
  tooltip: "switch_measurement_unit",
};
export const DELETE_ALL_TOOL = {
  id: "mt_delete_all",
  tooltip: "delete_all_measurements",
};
export const SWITCH_TOPOGRAPHY_MAP = {
  id: "mt_topography_map",
  tooltip: "toggle_topography_map",
};
export const OPEN_PROFILE_PANEL = {
  id: "mt_profile_panel",
  tooltip: "open_profile_panel",
};
export const ANNOTATION_TOOL = {
  id: TEXT_LABEL_ID,
  tooltip: "text_label_tool",
};
export const CENTER_POINT = { id: "center_point", tooltip: "text_label_tool" };
export const FIELD_OF_VIEW_TOOL = {
  id: "change_fov",
  tooltip: "change_fov_tool",
};
export const RESCALE_TOOL = {
  id: "rescale_model_tool",
  tooltip: "tooltipRescaleModelTool",
};
export const PERSPECTIVE_TOOL = {
  id: "perspective_model_tool",
  tooltip: "tooltipRotateModelTool",
};

// Volume tools
export const CREATE_VOLUME_TOOL = {
  id: "vt_create_volume",
  iconSrc: "/viewer/icons/clip_volume.svg",
  tooltip: "create_volume_tool",
};

export const MEASURING_TOOLS: INavElement[] = [
  ANNOTATION_TOOL,
  DISTANCE_TOOL,
  LOCATION_POINT_TOOL,
  AREA_TOOL,
  ANGLE_TOOL,
  SLOPE_TOOL,
  SWITCH_TOPOGRAPHY_MAP,
  OPEN_PROFILE_PANEL,
  FIELD_OF_VIEW_TOOL,
  RESCALE_TOOL,
  // PERSPECTIVE_TOOL,
];

export const VOLUME_TOOLS: INavElement[] = [
  CREATE_VOLUME_TOOL,
];


export enum VolumeClipTypes {
  None = 0,
  Highlight = 1,
  Inside = 2,
  Outside = 3,
}

export enum LengthUnits {
  YARD = "yd",
  FEET = "ft",
  INCH = "in",
  METER = "m",
  CENTIMETER = "cm",
  MILIMETER = "mm",
};

export const VOLUME_TYPE_BUTTONS = [
  { id: VolumeClipTypes.Highlight, label: 'volume_highlight_option' },
  { id: VolumeClipTypes.Inside, label: 'volume_inside_option' },
  { id: VolumeClipTypes.Outside, label: 'volume_outside_option' },
];