import axios from "axios";
import { getEnvVariables } from "../../../env";
import { SubscriptionPeriod } from "../../payments/payments.model";

const apiUrl = getEnvVariables().API_V3_URL;
const baseUrl = `${apiUrl}v3/auth`;

export interface IFinishRegistrationRequest {
  password: string;
  subscription: {
    type: string;
    period: SubscriptionPeriod;
  };
}

const useAuthRequests = () => {
  const registerWithToken = (email: string) => {
    return axios.post(`${baseUrl}/register-with-token`, { email });
  };

  const finishTokenRegistration = (token: string, registrationBody: any) => {
    return axios.post(
      `${baseUrl}/finish-token-registration?token=${token}`,
      registrationBody
    );
  };

  return {
    registerWithToken,
    finishTokenRegistration,
  };
};

export default useAuthRequests;
