import React, { useState } from "react";
import styled from "styled-components";
import Dialog from "../../../shared/components/Dialog/Dialog";
import { inject, observer } from "mobx-react";
import { IGlobalStore } from "stores/GlobalStore";
import { useTranslation } from "react-i18next";
import Title from "../../../shared/typography/Title";
import SelectRadioButton from "../../../shared/components/SelectRadioButton";
import { BoxAlignedCenter } from "../../../shared/components/Box";
import CircleLoader from "../../../shared/components/CircleLoader";
import { IAppContext } from "../../../interfaces";
import { FormGroup } from "../../../shared/form/FormGroup";
import SkyebrowseAppUpload from "./containers/SkyebrowseAppUpload";
import UniversalUpload from "./containers/UniversalUpload";
import AppUploadIcon from "./icons/AppUploadIcon";
import InteriorIcon from "./icons/InteriorUploadIcon";
import UniversalIcon from "./icons/UniversalUploadIcon";

const TitleWrapper = styled(BoxAlignedCenter)``;
const LoaderWrapper = styled.div`
  margin-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;

interface IUploadVideosDialogContainerPropTypes {
  GlobalStore?: IGlobalStore;
}

enum UploadTypes {
  SkyebrowseApp = "skyebrowse_app",
  Universal = "universal",
  InteriorMapping = "interior_mapping",
}

const UploadTypeButtons = [
  {
    id: UploadTypes.SkyebrowseApp,
    label: "upload.dialog.regularMappingLabel",
    description: "upload.dialog.regularUploadDescription",
  },
  {
    id: UploadTypes.Universal,
    label: "upload.dialog.universalUploadLabel",
    description: "upload.dialog.universalUploadDescription",
  },
  {
    id: UploadTypes.InteriorMapping,
    label: "upload.dialog.interiorMappingUploadLabel",
    description: "upload.dialog.interiorMappingUploadDescription",
  },
];

const ICONS_MAP = {
  [UploadTypes.SkyebrowseApp]: AppUploadIcon,
  [UploadTypes.Universal]: UniversalIcon,
  [UploadTypes.InteriorMapping]: InteriorIcon,
};

const getIcon = (type: UploadTypes) => {
  const Icon = ICONS_MAP[type];
  if (!Icon) return null;

  return <Icon />;
};

const UploadVideosDialogContainer = ({
  GlobalStore,
}: IUploadVideosDialogContainerPropTypes) => {
  const { t } = useTranslation();
  const { uploadDialogOpen } = GlobalStore;
  const [loading, setLoading] = useState(false);
  const [currentUploadType, setCurrentUploadType] = useState(null);

  const handleLoadingChange = (loading: boolean) => {
    setLoading(loading);
  };

  const handleUploadChange = (id: string) => {
    setCurrentUploadType(id as UploadTypes);
  };

  const getUpload = () => {
    if (!currentUploadType) return null;

    switch (currentUploadType) {
      case UploadTypes.InteriorMapping:
        return (
          <UniversalUpload
            onLoad={handleLoadingChange}
            defaultModelName={t("upload.defaultInteriorModelName")}
            interiorChecked
          />
        );
      case UploadTypes.Universal:
        return <UniversalUpload onLoad={handleLoadingChange} />;
      default:
        return <SkyebrowseAppUpload onLoad={handleLoadingChange} />;
    }
  };

  const close = () => {
    GlobalStore.closeUploadDialog();
  };

  return (
    <Dialog
      size="large"
      title={
        <TitleWrapper>
          {t("fileUpload")}
          {loading && (
            <LoaderWrapper>
              <CircleLoader color="primary" />
            </LoaderWrapper>
          )}
        </TitleWrapper>
      }
      open={!!uploadDialogOpen}
      onClose={close}
      hideCancelButton={true}
      hideConfirmButton={true}
      cancelButtonProps={{
        loading,
        loaderProps: {
          color: "primary",
        },
      }}
      confirmButtonProps={{
        onClick: close,
      }}
    >
      <div>
        <FormGroup>
          <Title>{t("chooseMappingType")}</Title>
          <div className="flex flex-col md:grid md:grid-cols-3 gap-4">
            {UploadTypeButtons.map((btn) => {
              return (
                <SelectRadioButton
                  noMargin
                  icon={getIcon(btn.id)}
                  id={btn.id}
                  key={btn.id}
                  label={t(btn.label)}
                  description={t(btn.description)}
                  onClick={handleUploadChange}
                  selected={currentUploadType === btn.id}
                ></SelectRadioButton>
              );
            })}
          </div>
        </FormGroup>
        {getUpload()}
      </div>
    </Dialog>
  );
};

export default inject("GlobalStore")(observer(UploadVideosDialogContainer));
