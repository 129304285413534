import { MODEL_V3_URL } from "../routes";
import HttpService from "services/HttpService";

const flightLogUrl = (modelId: string) => `${MODEL_V3_URL}/${modelId}/logs`;

interface UploadFileData {
  fileName: string;
}

interface GetPresignedUrlResponse {
  presignedUrl: string;
}

export class ModelFlightLogService {
  static async getPresignedUrlForLogs(
    modelId: string,
    uploadFileData: UploadFileData
  ): Promise<GetPresignedUrlResponse> {
    const url = flightLogUrl(modelId);
    const res = await HttpService.post(url, uploadFileData);
    return res.data;
  }
}
