import React, { useCallback } from "react";
import styled from "styled-components";
import DropZone from "views/shared/components/DropZone";
import { IAppContext } from "views/interfaces";
import fileUtils from "views/shared/utils/file";
import { MODEL_VIDEO_FILE_EXTENSIONS } from "const/global";
import { Trans, useTranslation } from "react-i18next";
import ModelService, { IMatchResult } from "views/models/services/ModelService";
import { ITranslationFields } from "locale/dictionary";
import { MessageService } from "services/MessageService";
import { INFORMATION_ROUTES } from "views/routes";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { IModelUploadContainer } from "../interface";
import { MatchingFailureReason } from "views/models/services/ModelService";
import { ModelUploadFileValidator } from "../utils/ModelUploadFileValidator";

interface ISkyebrowseAppUploadPropTypes extends IModelUploadContainer {}

const UploadInformationBox = styled.div`
  text-align: center;
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
  cursor: pointer;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.large};

  strong {
    font-weight: inherit;
    text-decoration: underline;
    color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
  }
`;
const DropZoneContainer = styled.div`
  .drop-zone-content {
    min-height: 592px;
    min-height: 190px;
  }
`;

const countByReason = (
  matchResult: IMatchResult,
  reason: MatchingFailureReason
) => {
  let counter = 0;
  matchResult.unmatched.forEach((unmatched) => {
    if (unmatched.reason === reason) {
      counter++;
    }
  });

  return counter;
};

const SkyebrowseAppUpload = ({
  VideoUploadStore,
  GlobalStore,
  history,
  onLoad,
}: ISkyebrowseAppUploadPropTypes) => {
  const { t } = useTranslation();
  const displayErrorToast = (dictionary: ITranslationFields, count: number) => {
    if (count) {
      MessageService.error(`${t(dictionary)} (${count})`);
    }
  };

  const uploadVideos = ({ matched }: IMatchResult, files: File[]) => {
    matched.forEach((match) => {
      VideoUploadStore.addToQueue(
        files.find((file) => match.fileName === file.name),
        match.fileName,
        match.modelInput.model.id,
        String(match.modelInput.id)
      );
    });
  };

  const matchAndUploadVideos = async (files: File[]) => {
    onLoad(true);

    try {
      const matchResult = await ModelService.getModelsUploadsVideosUrls(
        files.map((file) => ({
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          lastModified: file.lastModified,
        }))
      );

      const duplicatedCount = countByReason(
        matchResult,
        MatchingFailureReason.VIDEO_ALREADY_UPLOADED
      );
      const notFound = countByReason(
        matchResult,
        MatchingFailureReason.DATA_NOT_FOUND
      );

      if (duplicatedCount) {
        displayErrorToast("toastVideosUploadedCount", duplicatedCount);
      }

      if (notFound) {
        displayErrorToast("toastVideosNotMatchedUseEmergencyUpload", notFound);
      }

      uploadVideos(matchResult, files);
    } catch (error) {
      MessageService.error(t("requestFailed"));
    }

    onLoad(false);
    GlobalStore.uploadDialogOpen = false;
  };

  const onDrop = useCallback((files) => {
    const result = ModelUploadFileValidator(
      files,
      GlobalStore.user.organization.subscription.type.value
    );

    if (result.unknownFiles.length) {
      MessageService.error(
        `${t("toastWrongExtensionCount")} (${result.unknownFiles.length})`
      );
    }
    if (result.sizeExceededFiles.length)
      MessageService.error(t("upload.freemiumSizeExceeded"));

    matchAndUploadVideos(result.videoFiles);
  }, []);

  const handleEmergencyInfoClick = () => {
    history.push(INFORMATION_ROUTES.emergencyUpload);
    GlobalStore.uploadDialogOpen = false;
  };

  return (
    <DropZoneContainer>
      <DropZone onDrop={onDrop} backgroundColor="white" />
      <UploadInformationBox onClick={handleEmergencyInfoClick}>
        <Trans t={t}>emergencyUploadProblemInformation</Trans>
      </UploadInformationBox>
    </DropZoneContainer>
  );
};

export default withRouter(
  inject("GlobalStore", "VideoUploadStore")(observer(SkyebrowseAppUpload))
);
