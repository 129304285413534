import API from "../../../../const/api";
import HttpService from "../../../../services/HttpService";
import { IModelIssueReq } from "../model/Issue";

export class IssueService {
  static async reportPublicModel(publicKey: string, data: IModelIssueReq) {
    const url = API.publicModelIssue.replace(":publicKey", publicKey);
    return HttpService.post(url, data);
  }

  static async reportModel(id: string, data: IModelIssueReq) {
    const url = API.modelIssue.replace(":id", id);
    return HttpService.post(url, data);
  }
}
