import { SubscriptionPeriod } from "../../payments.model";

export const LITE_MONTHLY_PRICE = 59;
export const LITE_ANNUAL_PRICE = 49;

export const LITE_PRICE = (subscriptionType: SubscriptionPeriod): number => {
  return subscriptionType === SubscriptionPeriod.Monthly
    ? LITE_MONTHLY_PRICE
    : LITE_ANNUAL_PRICE;
};
