import { useModelDetailsPage } from "../useModelDetailsPage";
import { ModelDetailsPageTabKeys } from "../../../../../const/global";
import { ModelAccessPolicy } from "../../../ModelAccessPolicy";
import ContentSectionComponent from "../../components/ContentSection.component";
import ModelDetailsPageDownloadSection from "../DownloadSection/ModelDetailsPageDownloadSection";
import { useCurrentUser } from "../../../../account/context/user.context";

interface IContentSectionPropTypes {}

const ModelDetailsPageContentSection = ({}: IContentSectionPropTypes) => {
  const { user } = useCurrentUser();
  const { currentTabKey, model, loadModel, setCurrentTabKey } =
    useModelDetailsPage();

  return (
    <ContentSectionComponent
      downloadSectionComponent={
        <ModelDetailsPageDownloadSection model={model} />
      }
      model={model}
      onModelChange={() => loadModel(model.id)}
      currentTabKey={currentTabKey}
      setCurrentTabKey={(key) =>
        setCurrentTabKey(key as ModelDetailsPageTabKeys)
      }
      editable={ModelAccessPolicy.canEditModel(user, model)}
    />
  );
};

export default ModelDetailsPageContentSection;
