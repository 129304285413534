import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { inject, observer } from "mobx-react";
import ModelService, {
  ModelResolutionFilter,
  ModelTypeFilter,
} from "views/models/services/ModelService";
import modelSharedRequests from "requests/sharedModel";
import { IResponse } from "services/HttpService";
import { RouteComponentProps } from "react-router";
import { MODEL_ROUTES } from "views/routes";
import { Container } from "styled-bootstrap-grid";
import {
  IModelListFilters,
  ModelStatusFilter,
  ModelType,
} from "views/interfaces";
import { useTranslation } from "react-i18next";
import {
  AnalyticEventCategory,
  AnalyticService,
} from "../../../../services/AnalyticService";
import { withRouter } from "react-router-dom";
import Heading from "views/shared/typography/Heading";
import SkyePagination from "views/shared/components/SkyePagination";
import { IGlobalStore } from "../../../../stores/GlobalStore";
import ModelList from "./ModelList/ModelList";
import ModelFiltersRow from "./ModelFiltersRow";
import BasePage from "../../../shared/layouts/BasePage";
import { IModelList } from "./ModelList/interface";
import { ModelPaymentProvider } from "../../payments/ModelPayment.context";
import SubscriptionExpirationInfo from "../../../shared/components/SubscriptionExpirationInfo/SubscriptionExpirationInfo";
import { useGlobalEventEmitter } from "../../../shared/context/GlobalEventEmitter";
import { EventTypes } from "../../../../const/events";
import { useModelChangedSubscriber } from "../../hooks/web-socket/useModelChangedSubscriber";

interface IModelsListPagePathParams {
  page?: string;
}

interface IModelsListPageProps
  extends RouteComponentProps<IModelsListPagePathParams> {
  GlobalStore?: IGlobalStore;
}

const ModelListWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-evenly;
`;
const ListContainer = styled(Container)`
  padding: 0;
`;

const getModelRequest = (
  modelType: ModelType,
  page: number,
  selectedTags: string[],
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  status?: ModelStatusFilter,
  type?: ModelTypeFilter,
  resolution?: ModelResolutionFilter,
  name?: string,
  organizationId?: number
) => {
  const params: any = {
    archived: modelType === ModelType.Archived,
    tags: selectedTags,
    organizationId,
  };

  if (startDate) params.startDate = startDate.valueOf();
  if (endDate) params.endDate = endDate.valueOf();
  if (type) params.type = type;
  if (resolution) params.resolution = resolution;
  if (status !== ModelStatusFilter.all) params.status = status;
  if (name === "" || (name && name.length > 1)) params.name = name;

  switch (modelType) {
    case ModelType.SharedToOthers:
      AnalyticService.event(
        AnalyticEventCategory.ModelListPage,
        `Get shared to others list`
      );
      return modelSharedRequests.getModelsSharedToOthers(page, params);

    case ModelType.Shared:
      AnalyticService.event(
        AnalyticEventCategory.ModelListPage,
        `Get shared to me list`
      );
      return modelSharedRequests.getModels(page, params);

    default:
      AnalyticService.event(
        AnalyticEventCategory.ModelListPage,
        organizationId ? `Get organization models list` : `Get models list`
      );
      return ModelService.getModels(page, params);
  }
};

let searchDebounceTimer = null;

const ModelsListPage = (props: IModelsListPageProps) => {
  const { t } = useTranslation();
  const isInitializedRef = useRef(false);
  const eventEmitter = useGlobalEventEmitter();
  const { match, history, GlobalStore } = props;
  const [page, setPage] = useState(Number(match.params.page));
  const [currentFilters, setCurrentFilters] = useState<IModelListFilters>({
    modelType: ModelType.Owned,
    tags: [],
  });

  /**
   * Handling events from the web socket
   */
  useModelChangedSubscriber({
    onChanged: () => {
      changeFilters(currentFilters, page, false);
    },
  });

  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState<IModelList>([]);
  const [total, setTotal] = useState(0);

  const changeFilters = async (
    newFilters?: IModelListFilters,
    newPage?: number, // Starting from 1
    indicateLoading = true
  ) => {
    if (loading) return;
    const filters = { ...currentFilters, ...newFilters };
    const { modelType, tags, status, startDateFilter, endDateFilter, name } =
      filters;

    // State change
    setCurrentFilters(filters);
    if (newPage && newPage !== page) {
      setPage(newPage);
      changePaginationUrl(newPage);
    }

    clearTimeout(searchDebounceTimer);
    let type: ModelTypeFilter = null;
    let resolution: ModelResolutionFilter = null;

    // Filtering skyebrowse, widebrowse, ultra tags
    const filteredTags = tags.filter((tag) => {
      if (
        tag === ModelTypeFilter.skyebrowse ||
        tag === ModelTypeFilter.widebrowse
      ) {
        type = tag;
        return false;
      }

      if (tag === ModelResolutionFilter.ultra) {
        resolution = tag;
        return false;
      }

      return true;
    });

    if (indicateLoading) {
      setModels([]);
      setLoading(true);
    }

    const organizationId =
      modelType === ModelType.Organization
        ? GlobalStore.user?.organization?.id
        : null;

    getModelRequest(
      modelType,
      newPage,
      filteredTags,
      startDateFilter,
      endDateFilter,
      status,
      type,
      resolution,
      name,
      organizationId
    )
      .then((r: IResponse) => {
        if ([ModelType.Shared, ModelType.SharedToOthers].includes(modelType)) {
          setModels(r.data.records);
          setTotal(r.data.total);
          return;
        }

        setModels(r.data.models);
        setTotal(r.data.total);
      })
      .catch((e) => {
        AnalyticService.event(
          AnalyticEventCategory.ModelListPage,
          `List load error - listType: ${modelType}`
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFiltersChange = (filters: IModelListFilters, page: number) => {
    if (loading) return;
    changeFilters(filters, page);
  };

  const changePaginationUrl = (newPage: number) => {
    const queryString = window.location.href.split("?")[1];
    let newUrl = MODEL_ROUTES.list.replace(":page", String(newPage));

    if (queryString) {
      newUrl = `${newUrl}?${queryString}`;
    }

    history.push(newUrl);
  };

  const handlePaginationChange = (newPage) => {
    if (loading) return;
    changeFilters({}, newPage);
  };

  useEffect(() => {
    if (!isInitializedRef.current) {
      isInitializedRef.current = true;

      eventEmitter.subscribe(EventTypes.VideoUploaded, () => {
        changeFilters(currentFilters, page, false);
      });
      eventEmitter.subscribe(EventTypes.ModelCreated, () => {
        changeFilters(currentFilters, page, false);
      });
      return;
    }
  }, []);

  return (
    <BasePage backgroundColor="white">
      <ModelListWrapper id="modelListWrapper">
        <ListContainer>
          <SubscriptionExpirationInfo
            subscriptionType={
              GlobalStore.user?.organization?.subscription?.type?.value
            }
            subscriptionActive={GlobalStore.user?.organization.active}
            expirationDate={
              GlobalStore.user?.organization.subscription.expirationDate
            }
            showDaysUntil={30}
          />
          <ModelFiltersRow onChange={handleFiltersChange} loading={loading} />
          <ListContainer className="list-container">
            {!loading && (
              <Heading>
                {t("resultsCount").replace(":count", String(total))}
              </Heading>
            )}
            <ModelList
              loading={loading}
              models={models}
              listType={currentFilters.modelType}
              onReload={() => changeFilters()}
            />
          </ListContainer>
        </ListContainer>
        {!loading && (
          <SkyePagination
            loading={loading}
            currentPage={page}
            total={total}
            onChange={handlePaginationChange}
          />
        )}
      </ModelListWrapper>
    </BasePage>
  );
};

const withContext = (Component: any) => {
  return (props: any) => {
    return (
      <ModelPaymentProvider>
        <Component {...props} />
      </ModelPaymentProvider>
    );
  };
};

export default withContext(
  inject("GlobalStore")(observer(withRouter(ModelsListPage)))
);
