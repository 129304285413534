export const PAYMENT_ROUTES = {
  products: "/products",
};

export const MODEL_ROUTES = {
  list: "/models/:page",
  details: "/model/:id",
  sharedModelDetails: "/model/shared/:id",
};

export const PUBLIC_MODEL_ROUTES = {
  details: "/model/public/:publicKey",
};

export const AUTH_ROUTES = {
  login: "/login",
  register: "/register",
  passwordReset: "/password/:token",
  fullFormRegister: "/register/full",
  initTokenRegistration: "/init-token-registration",
  finishTokenRegistration: "/finish-token-registration",
};

export const IFRAME_ROUTES = {
  iframeInitTokenRegistration: "/iframe/init-token-registration",
};

export const PROFILE_ROUTE_BASE = "user";

export enum ProfileSubRoutes {
  account = "account",
  organization = "organization",
  subscription = "subscription",
}

export const PROFILE_ROUTES = {
  account: `/${PROFILE_ROUTE_BASE}/${ProfileSubRoutes.account}`,
  organization: `/${PROFILE_ROUTE_BASE}/${ProfileSubRoutes.organization}`,
  subscription: `/${PROFILE_ROUTE_BASE}/${ProfileSubRoutes.subscription}`,
};

export const USER_ROUTES = {
  delete: "/user/delete",
  accountDeleted: "/account-deleted",
};

export const APPLICATION_ROUTES = {
  model: MODEL_ROUTES.list,
};

export const SESSION_ROUTES = {
  home: "/session",
  sessionList: "/session/list",
  sessionCreate: "/session/create",
  sessionDetails: "/session/:id",
  sessionViewer: "/session/viewer/:id",
};

export const INFORMATION_ROUTES = {
  home: "/info",
  emergencyUpload: "/info/emergency-upload",
  cardNotShowingUp: "/info/card-not-showing-up",
  batchUpload: "/info/batch-upload",
  measurements: "/info/measurements",
  advancedDashboardTools: "/info/advanced-dashboard-tools",
  offlineMode: "/info/offline-mode",
  introduction: "/info/introduction",
};

export const INTERIOR_AI_ROUTES = {
  home: "/interior-ai",
};
