import React from "react";
import ModelListElement from "./ModelListElement";
import { getModelPageUrl } from "../../../../../../utils/globalUtils";
import { ModelPageViewTypes } from "../../../../../../const/global";
import { ModelAccessType } from "../../../../../../models/Model";
import { inject, observer } from "mobx-react";
import GlobalStore, {
  IGlobalStore,
} from "../../../../../../stores/GlobalStore";
import { IModelListElementProps, IModelListItem } from "../interface";
import { ModelAccessPolicy } from "../../../../ModelAccessPolicy";
import ModelBadge from "./components/ModelBadge/ModelBadge";

interface IArchivedModelListElementPropTypes extends IModelListElementProps {
  GlobalStore?: IGlobalStore;
  model: IModelListItem;
  onHide: (model: IModelListItem) => void;
  onReveal: (model: IModelListItem) => void;
}

const ArchivedModelListElement = (
  props: IArchivedModelListElementPropTypes
) => {
  const { model } = props;
  const accessLocked =
    model.accessType === ModelAccessType.Archived ||
    model.accessType === ModelAccessType.Expired;
  const accessType = ModelAccessPolicy.modelAccess(
    GlobalStore.user,
    model.accessType,
    model.accessExpiresAt
  );

  return (
    <ModelListElement
      accessType={accessType}
      accessLocked={accessLocked}
      badge={<ModelBadge status={model.status} accessType={accessType} />}
      allowToShowUploadButton
      showOptions={true}
      showShare={false}
      showHide={false}
      showReveal={true}
      showTacbrowse={false}
      key={model.id}
      onShare={() => {}}
      model={model}
      mapRedirectUrl={getModelPageUrl(model.id, ModelPageViewTypes.Map)}
      modelRedirectUrl={getModelPageUrl(model.id, ModelPageViewTypes.Model)}
      {...props}
    />
  );
};

export default inject("GlobalStore")(observer(ArchivedModelListElement));
