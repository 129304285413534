import { memo, useState } from "react";
import { ISharedOutModelItem } from "../../../../../models/SharedModel";
import { UserShort } from "../../../../../models/User";
import {
  AnalyticEventCategory,
  AnalyticService,
} from "../../../../../services/AnalyticService";
import UnshareDialogContainer from "../../../containers/UnshareDialogContainer";
import { IModelListActions } from "./interface";
import SharedOutModelListElement from "./ModelListElement/SharedOutModelListElement";

interface ISharedOutModelListPropTypes extends IModelListActions {
  sharedOutModels: ISharedOutModelItem[];
}

interface ToUnshare {
  modelId: string;
  user: UserShort;
}

const SharedOutModelList = ({
  sharedOutModels,
  onReload,
  ...rest
}: ISharedOutModelListPropTypes) => {
  const [unshareDialogOpen, setUnshareDialogOpen] = useState(false);
  const [toUnshare, setToUnshare] = useState<ToUnshare | null>(null);
  const handleUnshare = (modelId: string, user: UserShort) => {
    AnalyticService.event(
      AnalyticEventCategory.ModelListPage,
      `Unshare by email`
    );
    setToUnshare({ modelId, user });
    setUnshareDialogOpen(true);
  };

  const handleClose = () => {
    setToUnshare(null);
    setUnshareDialogOpen(false);
  };

  const handleUnshared = () => {
    setToUnshare(null);
    setUnshareDialogOpen(false);
    onReload();
  };

  return (
    <>
      {sharedOutModels.map((sharedOutModel) => {
        return (
          <SharedOutModelListElement
            key={sharedOutModel.model.id}
            sharedOutModel={sharedOutModel}
            onDelete={(user: UserShort) =>
              handleUnshare(sharedOutModel.model.id, user)
            }
            {...rest}
          />
        );
      })}
      <UnshareDialogContainer
        modelId={toUnshare?.modelId}
        shareToEmail={toUnshare?.user.email}
        open={unshareDialogOpen}
        onClose={handleClose}
        onUnshared={() => {
          handleUnshared();
        }}
      />
    </>
  );
};

export default SharedOutModelList;
