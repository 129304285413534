const InteriorUploadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4205_483"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path d="M0 1.90735e-06H24V24H0V1.90735e-06Z" fill="white" />
    </mask>
    <g mask="url(#mask0_4205_483)">
      <path
        d="M21.8438 17.625L12 23.2031L2.15625 17.625V6.375L12 0.796877L21.8438 6.375V17.625Z"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12 17.625C8.89842 17.625 6.375 15.1016 6.375 12C6.375 8.89842 8.89842 6.375 12 6.375C15.1016 6.375 17.625 8.89842 17.625 12C17.625 15.1016 15.1016 17.625 12 17.625Z"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12 0.796875V6.375"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M21.8438 17.625L16.8829 14.7902"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7.11708 14.7902L2.15625 17.625"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M2.15625 6.375L12 12L21.8438 6.375"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12 23.2031V12"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
);

export default InteriorUploadIcon;
