import React, { useCallback, useMemo, useState } from "react";
import SkyebrowseAuthPageLayout from "../layout";
import { useTranslation } from "react-i18next";
import InputGroup from "../../../shared/form/InputGroup";
import useAuthRequests from "../../hooks/useAuthRequests";
import { isPassword } from "../../../shared/utils/validationUtils";
import Button from "../../../shared/buttons/Button";
import Label from "../../../shared/typography/Label";
import InformationBox from "../../../shared/components/InformationBox/InformationBox";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AUTH_ROUTES } from "../../../routes";
import {
  SubscriptionPeriod,
  SubscriptionType,
} from "../../../payments/payments.model";
import LiteSubscriptionPaymentOption from "../../../payments/components/ModelPaymentDialog/components/LiteSubscriptionPaymentOption";
import FreemiumSubscriptionPaymentOption from "../../../payments/components/ModelPaymentDialog/components/FreemiumPaymentOption";
import CircleLoader from "../../../shared/components/CircleLoader";
import { WindowUtils } from "../../../../utils/windowUtils";

interface IRegisterTokenFinishPageProps extends RouteComponentProps {}

enum Steps {
  PASSWORD_FILL,
  SUBSCRIPTION_PICK,
}

const RegisterTokenFinishPage = ({
  history,
}: IRegisterTokenFinishPageProps) => {
  const authReq = useAuthRequests();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [state, setState] = useState({
    password: "",
    passwordRepeat: "",
  });
  const [token] = useState(WindowUtils.getQueryParam("token", ""));
  const [step, setStep] = useState<Steps>(Steps.PASSWORD_FILL);
  const { password, passwordRepeat } = state;
  const validationError = isPassword(password);
  const [registered, setRegistered] = useState(false);
  const [subscriptionPeriod, setSubscriptionPeriod] =
    useState<SubscriptionPeriod>(SubscriptionPeriod.Annually);

  const finishRegistration = useCallback(
    (subscriptionType: SubscriptionType) => () => {
      setIsLoading(true);
      setErrorMessage(null);
      authReq
        .finishTokenRegistration(token, {
          password,
          subscription: {
            type: subscriptionType,
            period: subscriptionPeriod,
          },
        })
        .then((res) => {
          if (subscriptionType === SubscriptionType.Freemium) {
            setIsLoading(false);
            setRegistered(true);
            return;
          }

          const data = res.data;
          window.location.href = data.url;
        })
        .catch((err) => {
          setIsLoading(false);
          setErrorMessage(
            err?.response?.data?.message ||
              err?.message ||
              "Something went wrong"
          );
        });
    },
    [authReq, token, password, subscriptionPeriod]
  );

  const handlePasswordFill = useCallback(() => {
    setSubmitted(true);
    setErrorMessage(null);

    if (validationError || isLoading) return;
    if (password !== passwordRepeat) {
      setErrorMessage("Passwords do not match");
      return;
    }

    setStep(Steps.SUBSCRIPTION_PICK);
  }, [isLoading, password, passwordRepeat, validationError]);

  const goToLogin = useCallback(() => {
    history.push(AUTH_ROUTES.login);
  }, [history]);

  const title = useMemo(() => {
    if (registered || isLoading || !token || errorMessage) return "";
    if (step === Steps.PASSWORD_FILL) return "Finish registration";
    if (step === Steps.SUBSCRIPTION_PICK) return "Choose subscription";
  }, [registered, isLoading, step, token, errorMessage]);

  const description = useMemo(() => {
    if (registered || isLoading || !token || errorMessage) return "";
    if (step === Steps.PASSWORD_FILL)
      return "Set your password to finish registration";
    if (step === Steps.SUBSCRIPTION_PICK)
      return "Choose subscription to finish registration";
  }, [registered, isLoading, step, token, errorMessage]);

  const registrationSuccess = useMemo(
    () => (
      <InformationBox
        transparent
        icon="success"
        title={"Registration success"}
        description="You can now login to your account."
        button={<Button onClick={goToLogin}>Go to login</Button>}
      ></InformationBox>
    ),
    [goToLogin]
  );

  const tokenNotFound = useMemo(
    () => (
      <InformationBox
        transparent
        icon="error"
        title={"Token not found"}
        description="Token is invalid or expired."
        button={<Button onClick={goToLogin}>Go to login</Button>}
      ></InformationBox>
    ),
    [goToLogin]
  );

  const requestErrorInfo = useMemo(
    () => (
      <InformationBox
        transparent
        icon="error"
        title={"Request failed"}
        description={errorMessage}
        button={<Button onClick={goToLogin}>Go to login</Button>}
      ></InformationBox>
    ),
    [errorMessage, goToLogin]
  );

  const errors = useMemo(
    () => (
      <div className="w-full">
        {submitted && validationError && (
          <Label color="danger" className="mt-2">
            {t(validationError)}
          </Label>
        )}
        {!!errorMessage && (
          <Label color="danger" className="mt-2">
            {errorMessage}
          </Label>
        )}
      </div>
    ),
    [validationError, errorMessage, submitted, t]
  );

  const passwordForm = useMemo(
    () => (
      <form className="w-full text-left">
        <InputGroup
          bordered
          fullWidth
          label={"passwordLabel"}
          inputProps={{
            id: "password",
            type: "password",
            value: password,
            placeholder: "newPasswordPlaceholder",
            onChange: (e: any) =>
              setState((old) => ({ ...old, password: e.target.value })),
          }}
        />
        <InputGroup
          bordered
          fullWidth
          inputProps={{
            id: "passwordRepeat",
            type: "password",
            value: passwordRepeat,
            placeholder: "newPasswordRepeatLabel",
            onChange: (e: any) =>
              setState((old) => ({
                ...old,
                passwordRepeat: e.target.value,
              })),
          }}
        />
        {errors}
        <div className="button-wrapper w-full">
          <Button
            id="login-button"
            fullSize
            onClick={handlePasswordFill}
            loading={isLoading}
            disabled={!!validationError}
          >
            <>{t("register")}</>
          </Button>
        </div>
      </form>
    ),
    [
      password,
      passwordRepeat,
      validationError,
      isLoading,
      handlePasswordFill,
      errors,
      t,
    ]
  );

  const loader = useMemo(
    () => (
      <div className="w-full h-full flex justify-center">
        <CircleLoader size={80} color="primary" />
      </div>
    ),
    []
  );

  const subscriptionPicker = useMemo(
    () => (
      <div className="w-full pt-4">
        {errorMessage && requestErrorInfo}
        {!errorMessage && (
          <div className="w-full flex flex-col-reverse md:grid grid-cols-2 gap-4">
            <div className="w-full h-full">
              <FreemiumSubscriptionPaymentOption
                onBuy={finishRegistration(SubscriptionType.Freemium)}
              />
            </div>
            <div className="w-full h-full">
              <LiteSubscriptionPaymentOption
                subscriptionPeriod={subscriptionPeriod}
                onBuy={finishRegistration(SubscriptionType.Lite)}
                onPeriodChange={setSubscriptionPeriod}
              />
            </div>
          </div>
        )}
      </div>
    ),
    [finishRegistration, subscriptionPeriod, requestErrorInfo, errorMessage]
  );

  return (
    <SkyebrowseAuthPageLayout
      title={title}
      description={description}
      pageType="finishRegisterByToken"
      contentSize="lg"
    >
      {token ? (
        <>
          {registered ? (
            registrationSuccess
          ) : (
            <>
              {step === Steps.PASSWORD_FILL ? (
                passwordForm
              ) : (
                <>{isLoading ? loader : subscriptionPicker}</>
              )}
            </>
          )}
        </>
      ) : (
        tokenNotFound
      )}
    </SkyebrowseAuthPageLayout>
  );
};

export default withRouter(RegisterTokenFinishPage);
