import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { ViewerService } from "../../services/ViewerService";
import { useTranslation } from "react-i18next";
import InputGroup from "../../../shared/form/InputGroup";
import {
  IAnnotation,
  IPotreeMeasurement,
  MeasurementTypesNames,
  Volume,
  VolumeTypeNames,
} from "../../interfaces";
import ViewerObjectTreeItem from "./ViewerObjectTreeItem";
import Button from "../../../shared/buttons/Button";
import TrashIcon from "../../../shared/Icons/TrashIcon";
import InformationBox from "../../../shared/components/InformationBox/InformationBox";
import LayersAddIcon from "../../../shared/Icons/LayersAddIcon";
import Dialog from "../../../shared/components/Dialog/Dialog";
import { ModelMeasurementService } from "../../../models/services/ModelMeasurementService";
import { IPublicModel } from "../../../../models/Model";
import BaseMeasure from "../../services/tools/BaseMeasure";
import AreaIcon from "../../../shared/Icons/AreaIcon";
import AnnotationIcon from "../../../shared/Icons/AnnotationIcon";
import ReferencePointIcon from "../../../shared/Icons/ReferencePointIcon";
import VolumeIcon from "../../../shared/Icons/VolumeIcon";
import DistanceIcon from "../../../shared/Icons/DistanceIcon";
import useAnnotations from "../../hook/useAnnotations";
import TextIcon from "../../../shared/Icons/TextIcon";
import {
  ViewerEventName,
  useViewerEventEmitter,
} from "../../context/ViewerEventEmitter";
import Viewer from "../../Viewer";

interface ILayersPropTypes {
  model: IPublicModel;
  onChange: () => void;
  volumes: Volume[];
  measurements: IPotreeMeasurement[];
}

const viewerService = new ViewerService();
const ElementsWrapper = styled.div``;

const ObjectTreeLayers = ({
  model,
  volumes,
  measurements,
  onChange,
}: ILayersPropTypes) => {
  const eventEmitter = useViewerEventEmitter();
  const { t } = useTranslation();
  const {
    annotations,
    removeAnnotation,
    reloadAnnotations,
    removeAllAnnotations,
  } = useAnnotations();
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);
  const [measureName, setMeasureName] = useState("");
  const [editedMeasurement, setEditedMeasurement] = useState<any>(null);
  const measurementsToDisplay = (measurements || []).filter(
    (measurement) => measurement.name !== MeasurementTypesNames.RescaleLine
  );
  const measurementsExists = Boolean(
    measurementsToDisplay?.length + annotations?.length
  );
  const volumesExists = Boolean(volumes?.length);

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleChange = () => {
    onChange();
  };

  const handleMeasurementNameChange = () => {
    if (!editedMeasurement) {
      return;
    }

    const measurement = BaseMeasure.getByUuid(editedMeasurement.uuid);
    if (measurement) {
      measurement.label = measureName || editedMeasurement.name;
    }

    setMeasureName("");
    setEditedMeasurement(null);
    closeModal();

    handleChange();
  };

  const handleDelete = (measurement: IPotreeMeasurement) => () => {
    BaseMeasure.remove(measurement);
    handleChange();
  };

  const handleEdit = (measurement: IPotreeMeasurement) => () => {
    setMeasureName(measurement.label);
    setEditedMeasurement(measurement);
    showModal();
  };

  const handleVisibilityChange = (measurement: IPotreeMeasurement) => () => {
    measurement.visible = !measurement.visible;
    BaseMeasure.setMeasurement(measurement);
    handleChange();
  };

  const handleRemoveAll = () => {
    if (measurements.length + annotations.length > 1) {
      setDeleteAllDialogOpen(true);
      return;
    }

    removeAll();
  };

  const saveFeaturesToDB = () => {
    ModelMeasurementService.postModelMeasurements(
      model.id,
      viewerService.getAllFeatures(model.id)
    );
  };

  const removeAll = () => {
    BaseMeasure.deleteAll();
    removeAllAnnotations();
    setDeleteAllDialogOpen(false);
    handleChange();
    saveFeaturesToDB();
  };

  const handleVolumeDelete = (volume: Volume) => () => {
    viewerService.removeVolume(volume);
  };

  const handleVolumeVisibilityChange = (volume: Volume) => () => {
    viewerService.setVolumeVisibility(volume, !volume.visible);
  };

  const volumeName = (idx: number) => {
    return `${t("volume")} - ${idx}`;
  };

  const getIcon = (name: string) => {
    switch (name) {
      case "Annotation":
        return AnnotationIcon;
      case MeasurementTypesNames.Area:
        return AreaIcon;
      case MeasurementTypesNames.Point:
        return TextIcon;
      case MeasurementTypesNames.CenterPoint:
        return ReferencePointIcon;
      case MeasurementTypesNames.Distance:
      case VolumeTypeNames.Volume:
        return VolumeIcon;
      default:
        return DistanceIcon;
    }
  };

  const handleRemoveAnnotation = (annotation: IAnnotation) => () => {
    removeAnnotation(annotation);
    saveFeaturesToDB();
  };

  useEffect(() => {
    eventEmitter.subscribe(ViewerEventName.Rotate, () => {
      removeAll();
    });
    reloadAnnotations();
  }, []);

  return (
    <>
      <ElementsWrapper id="layers-list-wrapper">
        {volumesExists &&
          volumes.map((volume, idx) => {
            const { visible } = volume;
            const name = volumeName(idx);

            return (
              <ViewerObjectTreeItem
                key={volume.uuid}
                label={name}
                visible={visible}
                showVisibility={true}
                Icon={VolumeIcon}
                onClick={() => {}}
                onDelete={handleVolumeDelete(volume)}
                onVisibilityChange={handleVolumeVisibilityChange(volume)}
              ></ViewerObjectTreeItem>
            );
          })}
        {measurementsExists && (
          <>
            {measurementsToDisplay.map((measurement) => (
              <ViewerObjectTreeItem
                key={measurement.uuid}
                label={measurement.label}
                visible={measurement.visible}
                showVisibility={true}
                Icon={getIcon(measurement.name)}
                onClick={handleEdit(measurement)}
                onDelete={handleDelete(measurement)}
                onVisibilityChange={handleVisibilityChange(measurement)}
              ></ViewerObjectTreeItem>
            ))}
            {annotations.map((annotation) => (
              <ViewerObjectTreeItem
                key={annotation.uuid}
                label={annotation.title}
                Icon={getIcon("Annotation")}
                onDelete={handleRemoveAnnotation(annotation)}
                // TODO: implement annotation visibility
                visible={true}
                showVisibility={false}
                onClick={() => {}}
                onVisibilityChange={() => {}}
              ></ViewerObjectTreeItem>
            ))}
          </>
        )}
        {!measurementsExists && (
          <InformationBox
            transparent
            description={t("objectTreeEmpty")}
            icon={<LayersAddIcon />}
          ></InformationBox>
        )}
        {measurementsExists && (
          <Button
            id="remove-all-layers-button"
            fullSize
            type="transparent"
            size="small"
            onClick={handleRemoveAll}
            endIcon={<TrashIcon />}
          >
            {t("removeAllMeasurements")}
          </Button>
        )}
      </ElementsWrapper>
      <Dialog
        open={deleteAllDialogOpen}
        title={t("deleteAllLayersDialogTitle")}
        onClose={() => setDeleteAllDialogOpen(false)}
        confirmButtonProps={{
          id: "remove-all-layers-confirm-button",
          onClick: removeAll,
          children: t("confirm"),
        }}
      ></Dialog>
      <Dialog
        open={modalVisible}
        title={t("measurementNameDialogTitle")}
        onClose={closeModal}
        confirmButtonProps={{
          onClick: handleMeasurementNameChange,
          children: t("save"),
        }}
      >
        <InputGroup
          bordered
          inputProps={{
            value: measureName,
            onChange: (e: any) => setMeasureName(e.target.value),
            placeholder: "measurementNameInputPlaceholder",
          }}
        />
      </Dialog>
    </>
  );
};

export default memo(ObjectTreeLayers);
