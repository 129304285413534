declare global {
  interface Window {
    viewer: any;
    Potree: any;
    THREE: any;
  }
}

export interface INavElement {
  id: string;
  tooltip: string;
}

export enum MeasurementTypesNames {
  Area = "Area",
  Point = "Point",
  Height = "Height",
  CoordinatePoint = "CoordinatePoint",
  CenterPoint = "CenterPoint",
  Distance = "Distance",
  Locator = "Locator",
  RescaleLine = "RescaleLine",
  Perspective = "Perspective",
}

export enum VolumeTypeNames {
  Volume = "Volume",
}

export interface Point {
  x: number;
  y: number;
  z: number;
}

export interface IThreeColor {
  r: number;
  g: number;
  b: number;
  getHexString: () => string;
}

export interface ICoordinate {
  longitude: number;
  latitude: number;
  altitude?: number;
}

export interface IPotreeMeasurement {
  annotation: string;
  name: MeasurementTypesNames;
  uuid: string;
  label: string;
  color: IThreeColor;
  points: any[];
  visible: boolean;
  areaLabelDisabled: boolean;
  getTotalDistance: () => number;
  getRealCoordinates: () => ICoordinate;
  getAllPointsRealCoordinates: () => ICoordinate[];
}

window.viewer = window.viewer || {};
window.Potree = window.Potree || {};

export type IViewerInitCallback = (success: boolean) => void;

export interface IViewerScale {
  value: number;
}

export interface IPotreeAnnotation {
  uuid: string;
  title: string;
  description: string;
  url: string;
}

export interface IPotreeFeatures {
  volumes: Object;
  measurements: Object;
  scale?: IViewerScale;
  rotation?: Object;
  annotations?: IPotreeAnnotation;
}

export interface Volume {
  uuid: string;
  visible: boolean;
  clip: boolean;
}

export interface IAnnotation {
  uuid: string;
  title: string;
  description: string;
  url: string;
}