import {
  ModelAccessType,
  IPublicModel,
  Model,
  OrganizationModelAccessType,
  ModelStatus,
} from "../../models/Model";
import { LoggedUser } from "../../models/User";
import { SubscriptionType } from "../payments/payments.model";

export interface IModelAccessPolicyProps {
  user: LoggedUser;
  model: IPublicModel;
}

export class ModelAccessPolicy {
  static isLocked(status: ModelStatus, accessType: ModelAccessType): boolean {
    return (
      [
        ModelStatus.Archived,
        ModelStatus.RestoringArchive,
        ModelStatus.Archiving,
      ].includes(status) ||
      [ModelAccessType.Archived, ModelAccessType.Expired].includes(accessType)
    );
  }

  static isAccesible(model: IPublicModel): boolean {
    return (
      model.accessType === ModelAccessType.Premium ||
      model.accessType === ModelAccessType.Preview
    );
  }

  static modelAccess(
    user: LoggedUser,
    accessType: ModelAccessType,
    modelAccessExpiresAt: string | null
  ): OrganizationModelAccessType {
    if (
      [ModelAccessType.Archived, ModelAccessType.Expired].includes(accessType)
    ) {
      return accessType as any;
    }

    const subscriptionType = user?.organization.subscription.type.value;
    if (!subscriptionType) return OrganizationModelAccessType.Preview;

    if (
      subscriptionType === SubscriptionType.Premium ||
      new Date(modelAccessExpiresAt) > new Date()
    ) {
      return OrganizationModelAccessType.Premium;
    }

    if (subscriptionType === SubscriptionType.Lite) {
      return OrganizationModelAccessType.Lite;
    }

    return OrganizationModelAccessType.Preview;
  }

  static hasModelAccess(user: LoggedUser, model: IPublicModel): boolean {
    return (
      user?.organization?.active || model.accessType === ModelAccessType.Premium
    );
  }

  static hasPremiumAccess(
    user: LoggedUser,
    model: { accessType: ModelAccessType; accessExpiresAt: string | null }
  ): boolean {
    return (
      ModelAccessPolicy.modelAccess(
        user,
        model.accessType,
        model.accessExpiresAt
      ) === OrganizationModelAccessType.Premium
    );
  }

  static canEditModel(user: LoggedUser, model: Model): boolean {
    return user?.id === model?.ownerId;
  }

  static canDownloadAssets(user: LoggedUser, model: IPublicModel): boolean {
    return (
      user?.organization?.active || model.accessType === ModelAccessType.Premium
    );
  }
}
